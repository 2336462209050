import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "New Technology Introduction (NTI)",
  "description": "NTI, or engineering acceleration process, is done to ",
  "author": "",
  "categories": ["onboarding"],
  "date": null,
  "featured": true,
  "tags": ["onboarding"],
  "relatedArticles": ["lifecycle-management", "innovation-consulting", "new-product-introduction-npi", "nsf"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`New Technological Innovation (NTI), or Engineering Acceleration, boosts the technical development of your product.`}</p>
    <p>{`The whole process not only involves the technical and engineering services, but also rapid testing, filtering high-quality projects, prototyping, and tooling services.`}</p>
    <blockquote>
      <p parentName="blockquote">{`In engineering acceleration, we aim to:`}</p>
      <p parentName="blockquote">{`accelerate the verification of technological innovation into new scenarios`}</p>
    </blockquote>
    <h2 {...{
      "id": "why-do-you-need-nti"
    }}>{`Why do you need NTI?`}</h2>
    <p>{`Manufacturing electronics product require numerous types of components. Hence, makers usually use multiple suppliers to handle their PCBA, SMT, EE, and ME.`}</p>
    <p>{`Factors, such as the `}<strong parentName="p"><em parentName="strong">{`quality`}</em></strong><em parentName="p">{`, `}<strong parentName="em">{`cost`}</strong>{`,`}</em>{` and `}<strong parentName="p"><em parentName="strong">{`time`}</em></strong>{` of the production process should be closely coordinated.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "822px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.768339768339764%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABcRAAAXEQHKJvM/AAACSUlEQVQoz2XRXUiTYRQH8L/fH5PNxfDVnB8r3fa8jtxKiwzNva+RKwitULowiCJDA9PEDzCT2J63VMoLUwwMIsTLLDBwYtKqJaLmsItGuaWkhS4/yLCieGLSIOrAuTgX5wfnf0AEGkMEqjKIttg08w1FxfmzMlabb3SNJpa4nqHbO3Nnanl2hm2ufarpfrwUDciDI9NrQUQJurxmhEergvB3EYFyRKCZvEAz9IJELMVXVMyqVo7U51aOO259mHx9b/7jW+fPb2ufexs7RvVy/eVE05E2NS9KCWk5dSERMs7PZAAICoBbrRckmA5ZAQxj9pomYinfot5weU59XVwoXfG4ilIA3L5r39pJz7OGa3OblIwx/7gdwAgAUwBUEIEmGURbfPLBtviasiqONRu0nsbsUt+rLufq+wm379202zs5pAEQUlDaIdfmNkXvLr4fFSHjYgBcBeCXOwEo/eA2IlDCi1SnNV/XFpXUcuzMXoX9Aal4MkyGxl60jC++ca5vrq+0l7cMJnPGBs5kaeV4UYrTZF8MBaAC4ASg++/kzAIrwD3E96ocWZ39pNLUhTjkQz4359jFfjELYyz0dHUfyqr7gtMPNET9eUPkVk5AcgBUEoHu4EWaqjNL6qLjDbHMmsU/qttvcdhvvnw+3jm24H7q+7HxZYD2OAxhmqrEPUfb1USwpRiP9Yb5YwBwGEBMAJQTgabyIt2pM0tJ5WWVClZfqPK2J1zwTRWz1blBtuyeZvMTA/sY8+LcpZ4oY2EriGBD1ol+/Fu/AXJj1e5dPpu5AAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e54d8c72cad73bb5270cb408316e617f/652f5/nti-01.webp 259w", "/static/e54d8c72cad73bb5270cb408316e617f/c29d2/nti-01.webp 518w", "/static/e54d8c72cad73bb5270cb408316e617f/355f1/nti-01.webp 822w"],
            "sizes": "(max-width: 822px) 100vw, 822px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/e54d8c72cad73bb5270cb408316e617f/a2ead/nti-01.png 259w", "/static/e54d8c72cad73bb5270cb408316e617f/6b9fd/nti-01.png 518w", "/static/e54d8c72cad73bb5270cb408316e617f/f73a1/nti-01.png 822w"],
            "sizes": "(max-width: 822px) 100vw, 822px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/e54d8c72cad73bb5270cb408316e617f/f73a1/nti-01.png",
            "alt": "NTI",
            "title": "NTI",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Tasks and risks to tackle:`}</p>
    <ul>
      <li parentName="ul">{`Creating and managing `}<strong parentName="li">{`quality control standards`}</strong>{` for different manufacturers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Coordinating timeline`}</strong>{` for product production`}</li>
      <li parentName="ul"><strong parentName="li">{`Managing requirements`}</strong>{` with different manufacturers.`}</li>
      <li parentName="ul"><strong parentName="li">{`Redefining cost allocation`}</strong>{` if problems arise.`}</li>
      <li parentName="ul"><strong parentName="li">{`Product development hindered`}</strong>{` due to one problematic production.`}</li>
      <li parentName="ul">{`Technical problem possibilities, requiring a `}<strong parentName="li">{`design change`}</strong>{` should be communicated to numerous parties.`}</li>
    </ul>
    <blockquote>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": ""
        }}><strong parentName="a">{`Lets start working on your project`}</strong></a><strong parentName="p">{`!`}</strong></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      